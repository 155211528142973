let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const menutop = document.querySelectorAll('#menu_top__body'),
      btn = document.querySelectorAll('.menu_top__ico'),
      popclose = document.querySelectorAll('.form_popup__close'),
      pop = document.querySelectorAll('.form_popup')
//alert(menutop);
btn[0].addEventListener('click', () => {
  menutop[0].classList.toggle('mt_visible');
});
/* document.getElementById('menu_top__ico').addEventListener('click', () => {
  menutop.classList.add('mt_visible');
}); */
popclose[0].addEventListener('click', () => {
  pop[0].classList.remove('form_popup__visible');
});